<template>
 <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Bills And Receipts Report Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Sales Bills
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Sales Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-sm-0 d-flex justify-content-end col-lg-7 p-1">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-12 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2" v-model="filter.session_year">
            <option v-for="(year,index) in sessionYears" :key="index" :value="year">{{year}}/{{parseInt(year.substring(year.length-2)) + 1}}</option>
          </select>
          <select v-model="filter.branch" class="filter-input ml-2">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevDay">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="toDay"> Today </a></li>
            <li>
              <a href="javascript:;" @click="nextDay">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevMonth">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="thisMonth"> Month </a></li>
            <li>
              <a href="javascript:;" @click="nextMonth">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filter.from"
              placeholder="From"
              class="filter-input"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filter.to"
              placeholder="To"
              class="ml-1 filter-input"
            />
          </div>
          <div class="ml-2">
            <input
                type="text"
                v-model="filter.key"
                class="filter-input"
                placeholder="Search..."
            />
          </div>
          <div class="float-right">
            <select class="filter-input ml-2 float-right" v-model="route" @change="filterReportType">
              <option value="/general-report/bills">Sales Bills</option>
              <option value="/general-report/purchasesBills">Purchase Bills</option>
              <option value="/general-report/payInReceipts">Pay In receipt</option>
              <option value="/general-report/payOutReceipts">Pay Out receipt</option>
              <option value="/general-report/salarySlips">Salary Slip</option>
            </select>
          </div>
        </div>
      </div>
    </div>
   
    <div class="row py-0">
     <div class="col-md-9 mx-auto" v-for="(details, index) in dataSets" :key="index">
   
            <div class="row">
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose','receiptSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
         
          </div>
          <div class="p-2 bill-container">
            <div class="text-center">
              <p class="font-xxl mb-0">Softechpark Pvt. Ltd.</p>
              <p class="mb-0">
                201 Something St., Something Town, YT 242, Country
                <br />Tel No: 324 445-4544 / youremail@companyname.com
                <br />PAN: 123456789
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0">
                <label class="content-label mb-1">Employee</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">ID : {{details.employee.employee_id}}</p>
                <p class="mg-b-0">NAME : {{details.employee.name}}</p>
                
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">Txn. ID : #{{details.id}}</p>
                <p class="mg-b-0 text-right">DATE : {{details.date}}</p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div class="col-sm-12 col-lg-12 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0 table_lr_border color-secondary">
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{details.payment_method.title}}</td>
                        <td>{{details.txn_id}}</td>
                        <td>{{details.paid_amt}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- col -->
            </div>
            <div class="row justify-content-between mt-1 font-s font-w-400">
              
              <div class="col-sm-0 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0">
                <!-- Note -->
                <div class="footer_detail mt-1 mb-0 p-2 ht-95p">
                  <label class="content-label mb-1">Amount in Word</label>
                  <p class="mb-0">
                    {{toWord(details.paid_amt)}} Only
                  </p>
                </div>
                <!-- Note -->
              </div>
              <!-- col -->
              <div class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0">
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Due Amount</span>
                    <span>{{details.due_amt}}</span>
                  </li>
                  </ul>
              </div>
            </div>
            
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{details.description}}
              </p>
            </div>
            <p class="text-right">User: {{details.user_id}}</p>
          </div>
        
  </div>
    </div>
  </div>
  
</template>
<script>
import { Search } from "../../../../../mixins/search";
import { mapGetters } from "vuex";
export default {
  mixins: [Search],
  data(){
    return{
        sessionYears:['2018','2019','2020','2021'],
      route:this.$route.path
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
  },
  methods: {
   
  filterByBranch() {
      if (this.dataLists.length != 0) {
        let branch = this.filter.branch;
        let details = this.dataLists.filter(function (data) {
          return data.branch.id == branch;
        });
        this.dataSets = { ...details };
      }
    },
    filterReportType() {
      if(this.$route.path!=this.route) {
        this.$router.push(this.route);
      }
    },
    filterByTwoDates() {
      this.$store.commit("setApiUrl", "api/billsReciepts/salarySlips/filter/date");
      this.$store.commit("sendData", this.filter);
    },
    filterByDay() {
      this.$store.commit("setApiUrl","api/billsReciepts/salarySlips/filter/day");
      this.$store.commit("sendData", {
        size: this.filter.size,
        day: this.filter.full_day,
      });
    },
    filterByMonth() {
      this.$store.commit("setApiUrl", "api/billsReciepts/salarySlips/filter/month");
      this.$store.commit("sendData", {
        size: this.filter.size,
        month: this.filter.full_month,
      });
    },
    getData() {
      this.$store.commit("getData", `api/billsReciepts/salarySlips/size/${this.filter.size}/year/${this.filter.session_year}`);
    },
  },
  beforeMount() {
    this.$store.commit("getData2", `api/branch`);
    this.getData();
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>